export default {
  Home: 'Home',
  Settings: 'Settings',
  'Country, Role, Facility And Language': 'Country, Role, Facility And Language',
  Country: 'Country',
  Role: 'Role',
  Facility: 'Facility',
  Language: 'Language',
  Save: 'Save',
  Germany: 'Germany',
  Spain: 'Spain',

  'Lot Review': 'Lot Review',
  Auctions: 'Auctions',
  'Assignment Entry': 'Assignment Entry',
  'Owner Setup': 'Owner Setup',
  Sellers: 'Sellers',
  'Seller Companies': 'Seller Companies',
  'Seller Personnel': 'Seller Personnel',
  Members: 'Members',
  'Service Templates': 'Service Templates',
  Facilities: 'Facilities',
  Employees: 'Employees',
  'My Recent Lots': 'My Recent Lots',
  MiHub: 'MiHub',
  Extracts: 'Extracts',
  'Lot Life Cycle': 'Lot Life Cycle',
  'Management Tools': 'Management Tools',
  'Reporting & Metrics': 'Reporting & Metrics',
  'Account Maintenance': 'Account Maintenance',
  Vendors: 'Vendors',
  'Quick Looks': 'Quick Looks',
  'Compliance Management': 'Compliance Management',
  'Lot Management': 'Lot Management',
  'Setup & Management': 'Setup & Management',
  Tools: 'Tools',
  'Back Office': 'Back Office',

  'Billing & Payments': 'Billing & Payments',
  'Bank Reconciliation': 'Bank Reconciliation',
  'Payouts': 'Payouts',
  'Account Postings': 'Account Postings',
  'Finance Reports': 'Finance Reports',
  'Invoice Receipt Discrepancy': 'Invoice Receipt Discrepancy',
  'Seller Pricing': 'Seller Pricing',
  'Member Pricing': 'Member Pricing',
  'Vendor Pricing': 'Vendor Pricing',
  'Company Setup': 'Company Setup',
  'Certificate of Export': 'Certificate of Export',
  'Print & Email': 'Print & Email',
  'IT Ops': 'IT Ops',
  'Batch Processing': 'Batch Processing',
  'Document Management': 'Document Management',

  'Germany Sales Admin': 'Germany Sales Admin',
  'Germany Mihub': 'Germany Mihub',
  'Germany Logistics': 'Germany Logistics',
  'Germany Executive': 'Germany Executive',
  'Germany General Setup': 'Germany General Setup',
  'Germany Managing Director': 'Germany Managing Director',
  'Germany Restricted Pii Access': 'Germany Restricted Pii Access',
  'Germany Office Employee': 'Germany Office Employee',
  'Germany Sales Executive': 'Germany Sales Executive',
  'Germany Vp Of Sales International': 'Germany Vp Of Sales International',
  'Germany Audit Finance': 'Germany Audit Finance',
  'Spain Office Employee': 'Spain Office Employee',
  'Spain Yard Employee': 'Spain Yard Employee',
  'Spain Executive': 'Spain Executive',
  'Spain Manager': 'Spain Manager',
  'Spain Managing Director': 'Spain Managing Director',
  'Spain Sales Executive': 'Spain Sales Executive',
  'Spain Vp Of Sales International': 'Spain Vp Of Sales International',
  'Spain Audit Finance': 'Spain Audit Finance',
  'Spain Mihub': 'Spain Mihub',
  'Germany Mihub Executive': 'Germany Mihub Executive',
  'Germany Gbp': 'Germany Gbp',
  'Spain Gbp': 'Spain Gbp',
  'Spain Mihub Executive': 'Spain Mihub Executive',
}
