import { fetcher, storage } from '@copart/ops-tool-kit'
import { isEmpty, propEq } from 'ramda'

// TODO: Structured validation of received data.

const IS_C_STACK = process.env.STACK === 'c'
const IS_G_STACK = process.env.STACK === 'g'

const matchByYardNumber = (list, n) => {
  return list.find((yard) => {
    return yard.number === n
  })
}

const getHomeYard = (list) => {
  return list.find(propEq('isHomeYard', 'Y')) || list[0]
}

const getSelectedYard = ({ userData, homeYard }) => {
  const selectedYard = storage.lastSelectedYard || homeYard
  const selectedYardMatch = matchByYardNumber(userData.yardList, selectedYard.yard_number)
  const yard = { ...selectedYard, ...selectedYardMatch }

  storage.setLocalItem('lastSelectedYard', yard)
  storage.setSessionItem('securityLevel', yard.userSecurityLevel || 0)
  return yard
}

const setUpSessionDashboard = (active, home) => {
  storage.setSessionItem('dashboard', {
    language: 'en',
    ...storage.getSessionItem('dashboard'),
    selectedCountry: active.yard_country_code.slice(0, 2),
    selectedCountryA3code: active.yard_country_code,
    selectedCurrency: active.yard_currency,
    selectedTimeZone: active.time_zone,
    selectedYard: active.yard_number,
    homeTimeZone: home.time_zone,
    homeYard: home.number,
    yard: active,
  })
}

const setUpLocalDashboard = (home) => {
  storage.setLocalItem('dashboard', {
    language: 'en',
    ...storage.getLocalItem('dashboard'),
    homeTimeZone: home.time_zone,
    homeYard: home.number,
  })
}

const setupUserData = async (userData) => {
    if (!IS_C_STACK) await fetcher.setUserRoles()

    // Not needed for g stack
    if (!IS_G_STACK) {
      const yardList = storage.getLocalItem('login', 'yardList')
      const homeYard = getHomeYard(yardList)
      const yardNumbers = await fetcher.getYardNumbers(homeYard.number)

      const lastSelectedYard = getSelectedYard({
        homeYard: {
          ...homeYard,
          ...yardNumbers[0],
        },
        userData,
      })

      if (!IS_C_STACK) {
        await fetcher.setUserRoles(lastSelectedYard.yard_number)

        if (storage.activeYardNumber !== lastSelectedYard.yard_number) {
          // Yard has changed based on role, refetch yard information and update selected yard
          const newYardNumbers: TODO = await fetcher.getYardNumbers(
            storage.activeYardNumber,
          )

          const yard: TODO = newYardNumbers[0]
          yard && storage.setLocalItem('lastSelectedYard', yard)
        }
      }

      setUpSessionDashboard(storage.lastSelectedYard, homeYard)
      setUpLocalDashboard(homeYard)
    }
  }

  export const attemptLogin = async (email, password) => {
    try {
      // Get user data, store tokens, get and store config, and get user yard data.
      const userData = await fetcher.login(email, password)
      await setupUserData(userData)
    return userData
  } catch (error) {
    console.error(error)
    return { error }
  }
}

export const attemptOktaLogin = async (code) => {
  try {
    // Get user data, store tokens, get and store config, and get user yard data.
    const userData = await fetcher.oktaLogin(code)
    await setupUserData(userData)
    return userData
  } catch (error) {
    console.error(error)
    return { error }
  }
}
