exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._HomeView-HomeView {\n  display: flex;\n  flex-direction: row;\n  justify-content: center;\n  padding: 0 40px 40px;\n  height: 100%;\n  width: 100%;\n}\n\n._HomeView-spinner {\n  margin: 80px 0 40px;\n  width: 100%;\n  height: 100%;\n\n  * {\n    width: 60px;\n    height: 60px;\n  }\n}\n", ""]);

// exports
exports.locals = {
	"HomeView": "_HomeView-HomeView",
	"spinner": "_HomeView-spinner"
};