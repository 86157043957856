exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._EmbeddedView-embeddedApp {\n    height: calc(100% - 100px);\n    width: 100%;\n    position: absolute;\n  }", ""]);

// exports
exports.locals = {
	"embeddedApp": "_EmbeddedView-embeddedApp"
};