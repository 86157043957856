import * as React from 'react'

import { withRouter } from 'react-router-dom'

import './LogoutView.css'

const MESSAGE = 'You have been logged out from Ops Portal'

@withRouter
export default class LogoutView extends React.PureComponent {
  state = { iframeOpened: false }

  componentDidMount() {
    this.toggleIframe()
  }

  handleLoad = () => {
    this.iframeTimer = setTimeout(() => {
      this.toggleIframe()
      window.location.assign(this.props.OKTA_LOGIN_URI)
    }, 3000)
  }

  toggleIframe = () => this.setState({ iframeOpened: !this.state.iframeOpened })

  componentWillUnmount = () => {
    clearTimeout(this.iframeTimer)
  }

  render() {
    return (
      <div styleName="LogoutView">
        <p>{MESSAGE}</p>
        <If condition={this.state.iframeOpened}>
          <p>Clearing Session...</p>
          <iframe src={this.props.SSO_LOGOUT_URI} onLoad={this.handleLoad} />
        </If>
      </div>
    )
  }
}
