exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._OktaView-LoginView {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n  margin-top: 65px;\n  padding: 0 40px 40px;\n}\n\n._OktaView-LoginForm {\n  background: rgb(241, 241, 241);\n  width: 350px;\n}\n\n._OktaView-header {\n  background: var(--primaryBlue);\n  background: var(--primaryBlue);\n  display: flex;\n  align-items: center;\n  padding-left: 10px;\n  width: 100%;\n  height: 40px;\n}\n\n._OktaView-header > ._OktaView-title {\n  color: var(--white);\n  color: var(--white);\n  font-size: 13.9px;\n  letter-spacing: 0.5px;\n  font-weight: 700;\n}\n\n._OktaView-OktaView {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  padding: 18px 15px 15px;\n}\n\n._OktaView-OktaView > p {\n  font-size: 14px;\n}\n\n._OktaView-OktaView > iframe {\n  border: none;\n  height: 1px;\n  width: 1px;\n}\n\n._OktaView-OktaView ._OktaView-SSOLogoutLink {\n  cursor: pointer;\n  color: blue;\n}\n\n._OktaView-OktaView ._OktaView-SSOLogoutLink:hover {\n  text-decoration: underline;\n}\n\n._OktaView-OktaView > * {\n  margin-bottom: 20px;\n}\n\n._OktaView-OktaView button {\n  background-color: rgb(27, 81, 168) !important;\n  color: rgb(255, 255, 255) !important;\n  border: 1px solid rgb(27, 81, 168) !important;\n  font-size: 14px !important;\n  font-weight: 600 !important;\n}\n\n._OktaView-OktaView button:hover div{\n  background-color: rgb(27, 81, 168, 1) !important;\n  height: 35px !important;\n}\n", ""]);

// exports
exports.locals = {
	"LoginView": "_OktaView-LoginView",
	"LoginForm": "_OktaView-LoginForm",
	"header": "_OktaView-header",
	"title": "_OktaView-title",
	"OktaView": "_OktaView-OktaView",
	"SSOLogoutLink": "_OktaView-SSOLogoutLink"
};