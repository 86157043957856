import React from 'react'
import Loadable from 'react-loadable'
import { Loader } from '@copart/core-components'

export const SettingsView = Loadable({
  loader: () => import(`./SettingsView.${process.env.STACK}`),
  loading: (props) => {
    return <Loader />
  },
})

// Use below export if settings does not load for whatever reason
// export SettingsView from './SettingsView'
