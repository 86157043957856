exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._LogoutView-LogoutView {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n._LogoutView-LogoutView > * {\n  margin-bottom: 20px;\n}\n\n._LogoutView-LogoutView > p {\n  font-size: 14px;\n}\n\n._LogoutView-LogoutView > iframe {\n  border: none;\n  height: 1px;\n  width: 1px;\n}\n", ""]);

// exports
exports.locals = {
	"LogoutView": "_LogoutView-LogoutView"
};