import { fetcher, storage } from '@copart/ops-tool-kit'
import { string } from '@copart/front-end-utils'

const generateRCExtensionUrl = () =>
  storage.getItem('config').endpoints?.ringCentralEnabledStatus

export const getRCExtensionStatus = async () => {
  try {
    const response = await fetcher.get(generateRCExtensionUrl())
    return response.data
  } catch (error) {
    return false
  }
}

export const resetPhoneSettingsBasedOnRCStatus = (ringCentralObj) => {
  let dashboardObject = storage.getLocalItem('dashboard')
  const {ring_central_phone_number, ring_central_extension} = ringCentralObj
  if (ringCentralObj.ring_central_enabled) {
    dashboardObject.ringCentralEnabled = true
    dashboardObject.phoneNumber = formatPhoneNumber(ring_central_phone_number, ring_central_extension)
    // dashboardObject.phoneNumber = `${ringCentralObj.ring_central_phone_number}*${ringCentralObj.ring_central_extension}`
  } else {
    if (dashboardObject.ringCentralEnabled) {
      dashboardObject.phoneNumber = ""
      delete dashboardObject.ringCentralEnabled
    }
  }

  storage.setLocalItem('dashboard', dashboardObject)
  const event = new Event('localStorageChanged');
  window.dispatchEvent(event);
}

function formatPhoneNumber(phoneNumber, extension) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  let formattedNumber = extension
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    formattedNumber = `${['(', match[2], ') ', match[3], '-', match[4]].join('')} * ${formattedNumber}`
  }
  return formattedNumber
}
