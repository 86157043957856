import { storage } from '@copart/ops-tool-kit'

const MEA_COUNTRY_CODES = ['BHR', 'OMN', 'ARE']
const isMEACountry = (code) => MEA_COUNTRY_CODES.includes(code)

export const getAccessToken = () => {
  return storage.accessToken
}

export const getCountryCode2Digit = () => {
  return storage.activeCountry
}

export const getCountryCode3Digit = () => {
  const selectedCountryA3code = storage.getSessionItem('dashboard')?.selectedCountryA3code
  return isMEACountry(selectedCountryA3code) ? 'MEA' : selectedCountryA3code
}

export const getYardNumber = () => {
  return storage.activeYardNumber
}

export const getSelectedRole = () => {
  return storage.userRole
}

export const getLanguage = () => {
  return storage.activeLanguage
}

export const getStandardPrinter = () => {
  const printers = storage.getSessionItem('dashboard')?.printers
  return printers && printers.standard_printers
}