import { fetcher } from '@copart/ops-tool-kit'

export const getOktaConfig = async () => {
  try {
    const endpoint = '/api/v0/auth/okta_config'

    const response = await fetcher(endpoint, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'GET',
    })

    if (typeof response.data === 'object') return response.data
    else return { error: 'An internal error occured' }
  } catch (error) {
    console.error(error)
    return { error }
  }
}
