import { groupBy, pluck } from 'ramda'
import { storage, fetcher } from '@copart/ops-tool-kit'
import { string as stringUtils } from '@copart/front-end-utils'

// TODO: Move to fetcher.
const getAppPermissions = async (selectedRole: string): [] => {
  try {
    const appPermissionsUrl: string = storage.getItem('config')?.endpoints?.appPermissions
    const url = stringUtils.substitute(appPermissionsUrl, { selectedRole })
    const country = storage.getLocalItem('dashboard', 'selectedCountryA3code')
    const headers = global.STACK === 'g' ? { headers: { country }} : { headers: { countryCode: 'US' } }
    const { data } = await fetcher.get(url, headers)

    if (Array.isArray(data)) {
      return data[0]?.roleApplicationFunctionRelations.map((data) => {
        return data?.applicationFunction
      })
    }

    return []
  } catch (error) {
    return Promise.reject(error)
  }
}

const getAppFunctionName = (target) => {
  return target?.appFuncName
}

export const fetchAccessControl = async () => {
  const selectedRole: string = storage.userRole

  if (!selectedRole) {
    Promise.reject('No selected role.')
  }

  try {
    const permissions = await getAppPermissions(selectedRole)
    const names = permissions.map(getAppFunctionName)
    const permissionsForStorage = permissions.reduce((accum, curr) => {
      accum[curr.appFuncName] = [curr]
      return accum
    }, {})
    storage.setLocalItem('settings', names, 'permissionIds')
    storage.setLocalItem('settings', permissionsForStorage, 'permissions')

    return permissions
  } catch (error) {
    console.error(error)
    return { error: 'There was an issue fetching the permissions for your account!!!' }
  }
}
