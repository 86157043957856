// @flow
import React from 'react'
import Loadable from 'react-loadable'
import { Loader } from '@copart/core-components'

export const LoginView = Loadable({
  loader: () => import('./LoginView'),
  loading: (props) => {
    return <Loader />
  },
})
