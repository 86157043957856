import * as React from 'react'

import { withRouter } from 'react-router-dom'
import { fetcher } from '@copart/ops-tool-kit'
import { Loader, Button } from '@copart/core-components'

import './OktaView.css'

const AUTHORIZING = 'Authorizing user credential'
const AUTHORIZING_ERROR = 'An error occured when authorizing user credential'
const OKTA_ERROR = 'There is an error from OKTA SSO'
const DEFAULT_ERROR = 'An unknown error occurred!'

@withRouter
export default class OktaView extends React.PureComponent {
  state = {
    isAuthorizing: false,
    hasError: false,
    errorMessage: '',
  }

  componentDidMount() {
    if (this.isRedirectedFromOkta()) {
      const error = this.getOktaError()
      if (error) this.hanldeOktaError(error)
      else this.oktaAuthorize()
    }
  }

  isRedirectedFromOkta = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const state = urlParams.get('state')
    if (state === 'okta') return true
    else return false
  }

  redirectToOkta = () => window.location.assign(this.props.OKTA_LOGIN_URI)

  oktaAuthorize = async () => {
    this.setState({ isAuthorizing: true })
    const code = this.getOktaCode()

    /// Fetch tokens (user credentials)
    const auth = await fetcher.attemptOktaLogin(code)

    if (auth.error || typeof auth === 'string') this.handleAuthorizeFailure(auth)
    else this.handleAuthorizeSuccess()
  }

  handleAuthorizeSuccess = () => this.props.history.push('/')

  handleAuthorizeFailure = (auth: string | {}) => {
    const error = auth?.error?.error_description || auth?.error || auth
    console.error(AUTHORIZING_ERROR, error)
    this.setState({
      hasError: true,
      errorMessage: typeof error === 'string' ? error : DEFAULT_ERROR,
    })
  }

  hanldeOktaError = (error) => {
    console.error(OKTA_ERROR, error)
    this.setState({
      hasError: true,
      errorMessage: error.errorDescription,
    })
  }

  getOktaCode = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.get('code')
  }

  getOktaError = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const error = urlParams.get('error')
    const errorDescription = urlParams.get('error_description')
    if (error) return { error, errorDescription }
    else return null
  }

  render() {
    return (
      <div styleName="LoginView">
        <div styleName="LoginForm">
          <header styleName="header">
            <p styleName="title">User Login</p>
          </header>
          <div styleName="OktaView">
            <p>Welcome to Ops Portal</p>
            <Choose>
              <When condition={this.state.hasError}>
                <p>{this.state.errorMessage}</p>
                <Button primary onClick={this.redirectToOkta}>
                  Retry Login
                </Button>
              </When>
              <Otherwise>
                <If condition={this.state.isAuthorizing}>
                  <Loader />
                  <p>{AUTHORIZING}</p>
                </If>
                <If condition={!this.state.isAuthorizing}>
                  <Button primary onClick={this.redirectToOkta}>
                    Login
                  </Button>
                </If>
              </Otherwise>
            </Choose>
          </div>
        </div>
      </div>
    )
  }
}
