export default {
  Home: 'Inicio',
  Settings: 'AJUSTES',
  'Country, Role, Facility And Language': 'País, Posición, Ubicación y Idioma',
  Country: 'País',
  Role: 'Posición',
  Facility: 'Ubicación',
  Language: 'Idioma',
  Save: 'Guardar',
  Germany: 'Alemania',
  Spain: 'España',

  'Lot Review': 'Revisión del Lote',
  Auctions: 'Subastas',
  'Assignment Entry': 'Crear Asignación/Lote',
  'Owner Setup': 'Configuración Propietario',
  Sellers: 'Vendedores',
  'Seller Companies': 'Empresas Vendedoras',
  'Seller Personnel': 'El personal del vendedor',
  Members: 'Compradores',
  'Service Templates': 'Plantillas Orden de Servicio',
  Facilities: 'Ubicaciones',
  Employees: 'Empleados',
  'My Recent Lots': 'Mis Lotes Recientes',
  MiHub: 'MiHub',
  Extracts: 'Extractos',
  'Lot Life Cycle': 'Ciclo de Vida del Lote',
  'Management Tools': 'Herramientas de Gestión',
  'Reporting & Metrics': 'Informes & Métricas',
  'Account Maintenance': 'Mantenimiento de Cuentas',
  Vendors: 'Proveedores',
  'Quick Looks': 'Vistas rápidas',
  'Compliance Management': 'Gestión de la conformidad',
  'Lot Management': 'Gestión del lote',
  'Setup & Management': 'Configuración y gestión',
  Tools: 'Herramientas',
  'Back Office': 'BackOffice',

  'Billing & Payments': 'Facturación y pagos',
  'Bank Reconciliation': 'Conciliación bancaria',
  Payouts: 'Pagos',
  'Account Postings': 'Contabilidad de cuenta',
  'Finance Reports': 'Informes financieros',
  'Invoice Receipt Discrepancy': 'Discrepancia en el recibo de la factura',
  'Seller Pricing': 'Conciliación bancaria',
  'Member Pricing': 'Precios de miembros',
  'Vendor Pricing': 'Precios de proveedores',
  'Company Setup': 'Configuración de la empresa',
  'Certificate of Export': 'Certificado de exportación',
  'Print & Email': 'Imprimir y enviar por correo',
  'IT Ops': 'Operaciones IT',
  'Batch Processing': 'Procesamiento en serie',
  'Document Management': 'Gestión de documentos',

  'Germany Sales Admin': 'Admin Ventas de Alemania',
  'Germany Mihub': 'Mihub de Alemania',
  'Germany Logistics': 'Logística de Alemania',
  'Germany Executive': 'Ejecutivo de Alemania',
  'Germany General Setup': 'Configuración General de Alemania',
  'Germany Managing Director': 'Director General de Alemania',
  'Germany Restricted Pii Access':
    'Acceso Restringido a la información personal de Alemania',
  'Germany Office Employee': 'Empleado de oficina de Alemania',
  'Germany Sales Executive': 'Ejecutivo de ventas de Alemania',
  'Germany Vp Of Sales International':
    'Vicepresidente de Ventas Internacional de Alemania',
  'Germany Audit Finance': 'Auditoría financiera Alemania',
  'Spain Office Employee': 'Empleado de oficina de España',
  'Spain Yard Employee': 'Empleado de campa de España',
  'Spain Executive': 'Ejecutivo de España',
  'Spain Manager': 'Manager Español',
  'Spain Managing Director': 'Director General de España',
  'Spain Sales Executive': 'Ejecutivo de Ventas de España',
  'Spain Vp Of Sales International': 'Vicepresidente de Ventas Internacional de España',
  'Spain Audit Finance': 'Auditoría Financiera España',
  'Spain Mihub': 'Mihub de España',
  'Germany Mihub Executive': 'Ejecutivo de Mihub de Alemania',
  'Germany Gbp': 'Alemania Gbp',
  'Spain Gbp': 'España Gbp',
  'Spain Mihub Executive': 'Ejecutivo de Mihub de España',
  'Yard Deposits' : 'Depósitos de Campa',
}
