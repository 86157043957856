import {
  has,
  map,
  head,
  filter,
  pick,
  includes,
  isEmpty,
  pathOr,
  keys,
  pluck,
} from 'ramda'
import { fetcher, storage } from '@copart/ops-tool-kit'
import { string as stringUtils } from '@copart/front-end-utils'
import { COUNTRIES, LANGUAGES, LanguageOptionsMapper } from '#consts/mappers'
import { NO_ASSIGNED_ROLE_ERROR } from '#consts/login'

const getUserPreferences = async () => {
  try {
    const url = stringUtils.substitute(storage.getItem('config').endpoints.userPrefs, {
      email: storage.getLocalItem('login').email,
    })
    const { data } = await fetcher.get(url)
    return data
  } catch (error) {
    return Promise.reject('Unable to fetch Roles')
  }
}

export const getLanguageOptions = (selectedCountry) =>
  includes(selectedCountry, keys(LANGUAGES))
    ? pluck(['value'], LanguageOptionsMapper(selectedCountry))
    : pluck(['value'], LanguageOptionsMapper())

export const getRolesMap = (userRoles) => {
  const rolesMap = {}
  userRoles.map((obj) => {
    if (has(obj.roleName, rolesMap))
      rolesMap[obj.roleName] = [...rolesMap[obj.roleName], obj.hierarchyId]
    else rolesMap[obj.roleName] = [obj.hierarchyId]
  })
  // Cobalt Ops Portal uses this rolesMap from settings
  storage.setLocalItem('settings', rolesMap, 'rolesMap')
  return rolesMap
}

export const getUserRolesFromFetcher = async () => {
  const promises = keys(COUNTRIES).map((country) => {
    return fetcher.getCobaltUserRoles(COUNTRIES[country].code)
  })
  const userRoles = await Promise.all(promises)
  return keys(COUNTRIES)
    .map((country, index) => ({ country, roles: userRoles[index] }))
    .filter((roleByCountry) => !isEmpty(roleByCountry.roles))
}

export const getSelectedYardNumberAndName = () => {
  const { selectedYard, yardList } = storage.getLocalItem('dashboard')
  const selectedYardObject =
    head(filter((yard) => yard.yard_number === selectedYard, yardList)) || {}
  return !isEmpty(selectedYardObject)
    ? `${selectedYardObject.yard_number} - ${selectedYardObject.yard_name}`
    : null
}

export const isValidLanguage = (language, selectedCountry) => {
  return includes(language, getLanguageOptions(selectedCountry))
}

export const getAccessCountries = (userRoles) =>
  userRoles.map((rolesByCountry) => rolesByCountry.country)

export const getActiveCountryObjByCountry = (userRoles, selectedCountry) =>
  userRoles.find((rolesByCountry) => rolesByCountry.country === selectedCountry)

export const setUserRoles = async (lastSelectedYard: number = 0) => {
  try {
    storage.setLocalItem('login', keys(COUNTRIES), 'allowedCountries')
    const userPrefData = await getUserPreferences()
    storage.setLocalItem('login', userPrefData, 'userPrefs')

    const UserRolesFromFetcher = await getUserRolesFromFetcher() // array of objects {country, roles: []}
    if (UserRolesFromFetcher.length === 0) {
      storage.setLocalItem('login', '')
      return Promise.reject(NO_ASSIGNED_ROLE_ERROR)
    }
    const activeCountryObj = getActiveCountryObjByCountry(
      UserRolesFromFetcher,
      storage.activeCountry || storage.getLocalItem('dashboard').selectedCountry,
    )
    const selectedCountry = pathOr(
      head(UserRolesFromFetcher).country,
      ['country'],
      activeCountryObj,
    )
    const userRoles = pathOr(
      head(UserRolesFromFetcher).roles,
      ['roles'],
      activeCountryObj,
    )
    const activeRole = userRoles.find(
      (role) => role.roleName === storage.getLocalItem('settings', 'selectedRole'),
    )
    const selectedRole = pathOr(head(userRoles).roleName, ['roleName'], activeRole)
    storage.setLocalItem('settings', selectedRole, 'selectedRole') || ''
    const rolesMap = getRolesMap(userRoles)
    const selectedLanguage =
      storage.activeLanguage || storage.getLocalItem('dashboard').language
    const language = isValidLanguage(selectedLanguage, selectedCountry)
      ? selectedLanguage
      : 'en'

    const selectedCountryA3code = COUNTRIES[selectedCountry].code
    const selectedYard = head(userRoles).hierarchyId
    const dashboard = { language, selectedCountry, selectedCountryA3code, selectedYard }
    localStorage.setItem('dashboard', dashboard)
    sessionStorage.setItem('dashboard', dashboard)
    const userFacilites = rolesMap[selectedRole]
    const allFacilities = await fetcher.getCobaltUserFacilities(selectedCountryA3code)
    const yardList = map(
      (facilityObj) => pick(['yard_number', 'yard_name'], facilityObj),
      filter(
        (facilityObj) => includes(facilityObj.yard_number, userFacilites),
        allFacilities,
      ),
    )
    storage.setLocalItem('dashboard', yardList, 'yardList')
    storage.setLocalItem('dashboard', { yard_number: selectedYard }, 'yard')
    storage.setSessionItem('dashboard', yardList, 'yardList')
    storage.setSessionItem('dashboard', { yard_number: selectedYard }, 'yard')
  } catch (error) {
    console.error('Failed to get roles', error)
    storage.setLocalItem('login', '')
    return Promise.reject(error || NO_ASSIGNED_ROLE_ERROR)
  }
}
