import * as React from 'react'

import { storage } from '@copart/ops-tool-kit'

import { fetchAccessControl } from '#utilities/accessControl'
import Category from './Category'
import './HomeView.css'
import { getRCExtensionStatus, resetPhoneSettingsBasedOnRCStatus } from '#utilities/ringCentralFunctions'

type PropsT = {}

type StateT = {
  ready: boolean,
  permissions: [],
}

const sortTileCategories = (tiles) => {
  return tiles.reduce((final, tile) => {
    if (tile.category in final) final[tile.category].push(tile)
    else final[tile.category] = [tile]
    return final
  }, {})
}

export class HomeView extends React.Component<PropsT, StateT> {
  state = {
    ready: false,
    permissions: [],
    error: '',
  }

  async componentDidMount() {
    if (global.STACK !== 'c') {
      try {
        const permissions = await fetchAccessControl()
        return this.setState({ ready: true })
      } catch (error) {
        return this.setState({ error })
      }
    }

    this.setState({ ready: true })

    //called after ready state is set to true
    //to ensure that rendering tiles is not blocked by ringcentral availability check
    if (global.STACK === 'c') {
      const ringCentralEnabled = await getRCExtensionStatus()
      resetPhoneSettingsBasedOnRCStatus(ringCentralEnabled)
    }
  }

  render() {
    const tiles = storage.getItem('config')?.tiles
    const categories = sortTileCategories(tiles)
    const kioskTile = tiles.find((tile) => tile.title === 'Kiosk')
    const isKioskUser = kioskTile?.userRules?.allow.some((name) =>
      storage.userEmail.includes(name),
    )

    return (
      this.state.ready && (
        <Choose>
          <When condition={isKioskUser}>
            <div styleName="HomeView" data-e2e="HomeView">
              <Category key="kioskTile" title="Tools" tiles={[kioskTile]} />
            </div>
          </When>
          <Otherwise>
            <div styleName="HomeView" data-e2e="HomeView">
              <For each="category" of={Object.entries(categories)}>
                <Category key={category[0]} title={category[0]} tiles={category[1]} />
              </For>
            </div>
          </Otherwise>
        </Choose>
      )
    )
  }
}
