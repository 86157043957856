export default {
  Home: 'Startseite',
  Settings: 'Einstellungen',
  'Country, Role, Facility And Language': 'Land, Rolle, Copart Yard und Sprache',
  Country: 'Land',
  Role: 'Rolle',
  Facility: 'Copart Yard',
  Language: 'Sprache',
  Save: 'Speichern',
  Germany: 'Deutschland',
  Spain: 'Spanien',

  'Lot Review': 'Lot Review',
  Auctions: 'Auktionen',
  'Assignment Entry': 'Auftrag Anlegen',
  'Owner Setup': 'Verwaltung Eigentümer',
  Sellers: 'Verkäufer',
  'Seller Companies': 'Verkäufer-Organisationen',
  'Seller Personnel': 'Verkäufer-Ansprechpartner',
  Members: 'Mitglieder',
  'Service Templates': 'Vorlage Services',
  Facilities: 'Yards',
  Employees: 'Mitarbeiter',
  'My Recent Lots': 'Meine neuesten Objekte',
  MiHub: 'MiHub',
  Extracts: 'Auszüge',
  'Lot Life Cycle': 'Fahrzeuglebenszyklus',
  'Management Tools': 'Management-Tools',
  'Reporting & Metrics': 'Berichte & Kennzahlen',
  'Account Maintenance': 'Kontoverwaltung',
  Vendors: 'Lieferanten',
  'Quick Looks': 'Kurzübersicht',
  'Compliance Management': 'Compliance-Handling',
  'Lot Management': 'Posten-Verwaltung',
  'Setup & Management': 'Setup & Verwaltung',
  Tools: 'Werkzeuge',
  'Back Office': 'Backoffice',

  'Billing & Payments': 'Abrechnungen & Zahlungen',
  'Bank Reconciliation': 'Bankabstimmung',
  Payouts: 'Auszahlungen',
  'Account Postings': 'Kontobuchungen',
  'Finance Reports': 'Finanzberichte',
  'Invoice Receipt Discrepancy': 'Diskrepanz beim Rechnungseingang',
  'Seller Pricing': 'Verkäuferpreise',
  'Member Pricing': 'Mitgliedsabrechnung',
  'Vendor Pricing': 'Anbieterpreise',
  'Company Setup': 'Firmen Setup',
  'Certificate of Export': 'Exportzertifikat',
  'Print & Email': 'Drucken & E-Mail',
  'IT Ops': 'IT-Ops',
  'Batch Processing': 'Batch-Verarbeitung',
  'Document Management': 'Dokumenten-Management',
  'Insurance Partner Service': 'Versicherungs Partner Service',

  'Germany Sales Admin': 'Sales Admin',
  'Germany Mihub': 'Mihub',
  'Germany Logistics': 'Logistik',
  'Germany Executive': 'leitender Angestellter',
  'Germany General Setup': 'General ZUgriff',
  'Germany Managing Director': 'Geschäftsführer Deutschland',
  'Germany Restricted Pii Access': 'eingeschränkter PII Zugriff',
  'Germany Office Employee': 'Bürokraft',
  'Germany Sales Executive': 'Verkaufsleiter',
  'Germany Vp Of Sales International': 'Vizepräsident für internationalen Vertrieb',
  'Germany Audit Finance': 'Audit Finanzen',
  'Spain Office Employee': 'Spanien-Office-Mitarbeiter',
  'Spain Yard Employee': 'Spanien-Yard-Mitarbeiter',
  'Spain Executive': 'Spanien-Executive',
  'Spain Manager': 'Spanien-Manager',
  'Spain Managing Director': 'Geschäftsführer Spanien',
  'Spain Sales Executive': 'Verkaufsleiter Spanien',
  'Spain Vp Of Sales International': 'Spanien-Vice-President Of Sales International',
  'Spain Audit Finance': 'Spanien-Audit-Finanzen',
  'Spain Mihub': 'Spanien Mihub',
  'Germany Mihub Executive': 'Deutschland Mihub Executive',
  'Germany Gbp': 'Deutschland-Gbp',
  'Spain Gbp': 'Spanien-Gbp',
  'Spain Mihub Executive': 'Spanien Mihub Executive',
  'Yard Deposits' : 'Yard Einzahlungen',
}
