import './utilities/fetcherExtends'
import * as React from 'react'
import ReactDOM from 'react-dom'
import BrowserRouter from 'react-router-dom/BrowserRouter'
import createContext from 'create-react-context'
import withRouter from 'react-router-dom/withRouter'
import { AppFrame } from '@copart/ops-tool-kit'
import { any, includes } from 'ramda'
import AppRouter from './AppRouter'

React.createContext = React.createContext || createContext

// TODO: Provide router from ops-tool-kit.

// TODO: Ensure all apps set their super-domain.
// Potentially by handling this in tool kit.
global.STACK = process.env.STACK

const getDomain = (url) => {
  const regexCobaltCountry = /\.copart\.([a-z]{2})/
  const country = url.match(regexCobaltCountry) || [true, 'de']
  if (includes('copart.com', url)) {
    return 'copart.com'
  }
  if (country[1].toLowerCase() === 'de') {
    return 'copart.de'
  } else if (country[1].toLowerCase() === 'es') {
    return 'copart.es'
  } else if (country[1].toLowerCase() === 'fi') {
    return 'copart.fi'
  } else {
    return 'copart.com'
  }
}

const domainMapper = (stack) =>
  ({
    c: 'copart.com',
    uk: 'copart.co.uk',
    g: getDomain(window.location.hostname),
  }[stack])

document.domain = domainMapper(process.env.STACK)

const Frame = withRouter(AppFrame)

ReactDOM.render(
  <BrowserRouter>
    <Frame handleScrolling={false}>
      <AppRouter />
    </Frame>
  </BrowserRouter>,
  document.getElementById('mountPoint'),
)
