import styled from 'styled-components'

export const getBorder = (props) => {
  return props.inDevelopment
    ? '2px dashed rgb(204,204,204)'
    : '2px solid rgb(204,204,204)'
}

export const getBackground = (props) => {
  return props.disallowed ? 'rgb(241,241,241)' : 'transparent'
}

export const getColor = (props) => {
  return props.allowed ? 'rgb(61, 70, 86)' : 'rgb(204,204,204)'
}

export const getCursor = (props) => {
  return props.allowed ? 'pointer' : 'not-allowed'
}

export const getIconOpacity = (props) => {
  return props.allowed ? '1' : '0.225'
}

const handleNotAllowedHover = (props) => {
  if (props.allowed) return

  const text = props.disallowed ? 'Restricted' : 'Coming Soon'
  const background = 'rgba(0,0,0,0.55)'

  return `
    background: ${background};
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0.
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    content: "${text}";
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    font-family: 'Roboto', sans-serif;
    letter-spacing: 0.3px;
  `
}

const Container = styled.div`
  box-sizing: border-box;
  width: 100px;
  height: 100px;
  border-radius: 3px;
  background: ${getBackground};
  border: ${getBorder};
  color: ${getColor};
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 10px;
  margin-bottom: 10px;
  cursor: ${getCursor}

  :nth-of-type(2n+1) {
    margin-right: 10px;
  }

  img {
    display: block;
    max-width: 28px;
    margin: 0 auto;
    overflow: visible;
    opacity: ${getIconOpacity};
  }

  p {
    margin: 0;
    font-size: 11px;
    margin-top: 10px;
    font-weight: 700;
    line-height: 1.4;
    text-align: center;
  }

  :hover::after {
    ${handleNotAllowedHover}
  }
`

export default Container
