import React, { useState, useEffect } from 'react'
import Switch from 'react-router-dom/Switch'
import Route from 'react-router-dom/Route'
import IframeComponent from './IframeComponent'
import { getCountryCode3Digit } from '../../utilities/storageUtils'


const getIframeUrl = (config) => {
  if (config.url) return config.url
  if (config.urls) {
    const countryCode = getCountryCode3Digit()
    return config.urls[countryCode]
  }
}

export const EmbeddedView = () => {
    const [ready, setReady] = useState(false)
    const [routes, setRoutes] = useState([])
  
    useEffect(() => {
      const interval = setInterval(() => {
        const config = storage.getLocalItem('opsportal-core:config')
        if (config && config.tiles) {
          const iframeAppTiles = config.tiles.filter(tile => tile.appName === "embedded")
          setRoutes(
            iframeAppTiles.map(app => {
              const iframeAppRoute = app.path.split('/').pop()
              const iframeConfig = config.iframeApps && config.iframeApps.find(iframeApp => iframeApp.route === `/${iframeAppRoute}`)
              return iframeConfig && (
                <Route
                  key={app.path}
                  path={`/embedded${iframeConfig.route}`}
                  render={(props) => {
                    return (
                    <IframeComponent
                      title={app.title}
                      route={`/embedded${iframeConfig.route}`}
                      url={getIframeUrl(iframeConfig)}
                      urlProps={iframeConfig.urlProps}
                      customParams={iframeConfig.customParams}
                      redirectToHomeUrls={iframeConfig.redirectToHomeUrls}
                      tileConfig={app}
                      passHashToApp={iframeConfig.passHashToApp}
                      uriChangeEvents={iframeConfig.uriChangeEvents}
                      {...props}
                    />
                  )}} />
              )
            })
          )
          setReady(true)
          clearInterval(interval)
        }
      }, 100)
    }, [])
    return (
      <>
          {
            ready ? (
              <Switch>
                {routes}
              </Switch>
            ) : (
              <div>Loading...</div>
            )
          }
      </>
    )
  }