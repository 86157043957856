export const COUNTRIES = {
  DE: { name: 'Germany', code: 'DEU' },
  ES: { name: 'Spain', code: 'ESP' },
  FI: { name: 'Finland', code: 'FIN' },
}

export const LANGUAGES = {
  DE: { value: 'de', name: 'Deutsch' },
  EN: { value: 'en', name: 'English' },
  ES: { value: 'es', name: 'Spanish' },
  FI: { value: 'fi', name: 'Finnish' },
}

export const LanguageOptionsMapper = (selectedCountry = 'default') =>
  ({
    DE: [LANGUAGES.EN, LANGUAGES.DE],
    ES: [LANGUAGES.EN, LANGUAGES.ES],
    FI: [LANGUAGES.EN, LANGUAGES.FI],
    default: [LANGUAGES.EN, LANGUAGES.DE, LANGUAGES.ES, LANGUAGES.FI],
  }[selectedCountry])
