import { fetcher, storage } from '@copart/ops-tool-kit'

export const getCobaltUserRoles = async (country) => {
  try {
    const url = storage.getItem('config').endpoints.getRoles
    const { data } = await fetcher.get(url, {
      headers: { country },
    })
    return data
  } catch (error) {
    return Promise.reject('Unable to fetch Roles')
  }
}
