import * as React from 'react'
import { purist } from '@reacting/purist'

import Tile from '../Tile'
import Container from './styles'
import withSortedTiles from './hocs/withSortedTiles'

const Category = (_props) => {
  const props = withSortedTiles(_props)
  const tileCount = props.tiles.allowed.length + props.tiles.inDevelopment.length

  return (
    <Choose>
      <When condition={!tileCount}>{null}</When>
      <Otherwise>
        <Container>
          <h3>{props.title}</h3>
          <div className="tilesContainer">
            <For each="tile" of={props.tiles.allowed}>
              <Tile
                {...tile}
                allowed={true}
                disallowed={false}
                inDevelopment={false}
                key={tile.path}
              />
            </For>
            <For each="tile" of={props.tiles.inDevelopment}>
              <Tile
                {...tile}
                allowed={false}
                disallowed={false}
                inDevelopment={true}
                key={tile.path}
              />
            </For>
          </div>
        </Container>
      </Otherwise>
    </Choose>
  )
}

export default Category
