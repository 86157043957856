// @flow
// Fetch printer for the selectedYard and return the printerResults reference data.
import { fetcher, storage } from '@copart/ops-tool-kit'
import { string } from '@copart/front-end-utils'
import { values, reduce, map } from 'ramda'

const modifyPrinterConfig = ({ printer_name, is_default }) => ({
  printerName: printer_name,
  isDefault: is_default,
})

const modifyPrintersConfig = map(modifyPrinterConfig)

const generatePrintersUrl = (yardNumber: number): string => {
  return string.substitute(
    storage.getLocalItem('opsportal-core:config').endpoints.printers,
    {
      yardNumber,
    },
  )
}

export const getPrintersForYard = async (yardNumber: number) => {
  const response = await fetcher.get(generatePrintersUrl(yardNumber))
  return response.data
}

const generatePrintersUrlUK = (): string =>
  storage.getLocalItem('opsportal-core:config').endpoints.printers

export const getPrintersForUKYard = async (yardNumber: number) => {
  const response = await fetcher.get(generatePrintersUrlUK(), {
    headers: { 'x-yard': yardNumber },
  })
  const data = {
    ...response.data.data,
    barcode_labels_printers: response.data.data.barcode_label_printers,
  }
  delete data.barcode_label_printers
  return map(modifyPrintersConfig, data)
}
