import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 240px;

  h3 {
    margin-bottom: 10px;
    text-transform: uppercase;
    font-size: 16px;
  }

  &:nth-of-type(1) h3 {
    color: rgb(29, 90, 185);
  }

  &:nth-of-type(2) h3 {
    color: rgb(241, 150, 0);
  }

  &:nth-of-type(3) h3 {
    color: rgb(62, 178, 67);
  }

  &:nth-of-type(4) h3 {
    color: rgb(134, 70, 190);
  }

  .tilesContainer {
    display: flex;
    flex-wrap: wrap;
  }

  :not(:last-of-type) {
    margin-right: 10px;
  }
`

/*

  width: 100px;
  height: 100px;
  background: transparent;
  border-radius: 3px;
  border: 2px solid rgb(204, 204, 204);
 */

export default Container
