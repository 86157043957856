import { fetcher } from '@copart/ops-tool-kit'

import { attemptLogin, attemptOktaLogin } from './attemptLogin'
import { getOktaConfig } from "./getOktaConfig"
import { setUserRoles } from './setUserRoles'
import { getCobaltUserFacilities } from './getCobaltUserFacilities'
import { getCobaltUserRoles } from './getCobaltUserRoles'

fetcher.attemptLogin = attemptLogin
fetcher.attemptOktaLogin = attemptOktaLogin
fetcher.getOktaConfig = getOktaConfig
fetcher.setUserRoles = setUserRoles
fetcher.getCobaltUserFacilities = getCobaltUserFacilities
fetcher.getCobaltUserRoles = getCobaltUserRoles
