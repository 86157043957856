import locales from '../consts/locale'
import { isEmpty, isNil, either } from 'ramda'
import { storage } from '@copart/ops-tool-kit'

export default (text, ...args) => {
  if (text === undefined || text === null) return

  const language = storage.getLocalItem('dashboard').language
  const currentLocale = either(isNil, isEmpty)(language) ? 'en' : language

  let translatedPhrases = locales[currentLocale]
  const translation =
    typeof translatedPhrases[text] === 'function'
      ? translatedPhrases[text](args)
      : translatedPhrases[text]
  if (translation !== undefined && translation !== null) return translation

  const arr = text.split('.')
  if (isEmpty(arr)) {
    return text.toString()
  } else if (arr && arr.length === 1) {
    const translatedValue = translatedPhrases[text]
    return translatedValue ? translatedValue.toString() : text.toString()
  } else if (arr.length > 1) {
    arr.map(object => {
      if (translatedPhrases) {
        translatedPhrases = translatedPhrases[object]
      }
    })
  }
  return translatedPhrases ? translatedPhrases.toString() : text
}
