import withPropsInterceptor from '#utilities/hocs/withPropsInterceptor'
import { accessHelpers } from '@copart/ops-tool-kit'

const hasDuplicates = (target, value) => {
  return target.indexOf(value) !== target.lastIndexOf(value)
}

const sortTiles = tiles => {
  const titles = []
  return tiles.reduce(
    (final, tile) => {
      if (tile.inDevelopment) {
        final.inDevelopment.push(tile)
      } else if (titles.includes(tile.title)) {
        // Don't process if the title is already processed
        return final
      } else if (accessHelpers.userHasAccess(tile)) {
        titles.push(tile.title)
        final.allowed.push(tile)
      }
      return final
    },
    {
      allowed: [],
      inDevelopment: [],
    },
  )
}

const getTileTitles = (tiles = []) => {
  return tiles.map(tile => tile.title)
}

const findDuplicateTitles = titles => {
  return titles.map(title => {
    return hasDuplicates(titles, title) ? title : null
  })
}

const getUniqueDuplicateTitles = duplicates => {
  return Array.from(new Set(findDuplicateTitles(duplicates).filter(Boolean)))
}

const getAllTilesWithTitle = tiles => title => {
  return tiles.filter(tile => tile.title === title)
}

const testTileAccess = (dupes: [][]) => {
  return dupes.map(dupeSet => {
    const firstFails = accessHelpers.userHasAccess(dupeSet[0]) && dupeSet[1]
    const secondFails = accessHelpers.userHasAccess(dupeSet[1]) && dupeSet[0]

    return firstFails || secondFails || dupeSet[0]
  })
}

const withDuplicatesTilesHandled = props => {
  const dupeTitles = getUniqueDuplicateTitles(getTileTitles(props.tiles))
  const dupeTiles = dupeTitles.map(getAllTilesWithTitle(props.tiles))
  const failedTiles = testTileAccess(dupeTiles)

  const tiles = props.tiles
    .map(tile => {
      if (failedTiles.includes(tile)) return null
      return tile
    })
    .filter(Boolean)

  return {
    ...props,
    tiles,
  }
}

const withSortedTiles = props => {
  if (props.tiles.length === 1 && props.tiles[0].title === 'Kiosk') {
    return {
      ...props,
      tiles: {
        allowed: props.tiles,
        inDevelopment: [],
      },
    }
  }

  const { tiles } = withDuplicatesTilesHandled(props)

  return {
    ...props,
    tiles: sortTiles(tiles),
  }
}

export default withSortedTiles
