import * as React from 'react'
import { storage } from '@copart/ops-tool-kit'
import locale from '#utilities/locale'

import Container from './styles'

const NOOP = () => {}

const openNewTab = (path) => () => {
  return window.open(`${path}${storage.activeYardNumber}`, '_blank')
}

const urlProps = (props) => {
  const title = props.title || ''
  const urlParams =
    global.STACK === 'c' && title.startsWith('Seller')
      ? { onClick: openNewTab(props.path) }
      : { href: props.path }
  return !props.allowed ? { onClick: NOOP } : urlParams
}

export const Tile = (props) => {
  return (
    <a {...urlProps(props)} target={props.clickTarget === 'newTab' ? '_blank' : null}>
      <Container {...props}>
        <img src={props.iconUrl} />
        <p>{locale(props.title)}</p>
      </Container>
    </a>
  )
}

Tile.defaultProps = {
  title: '',
  path: '/',
  clickTarget: null,
  iconUrl: '',
}
