// Login error messages.

export const INVALID_CREDENTIALS_ERROR = `Login failed due to incorrect credentials. After 3 tries, account will be locked.`

export const USER_ACCOUNT_LOCKED_ERROR =
  'Your Account has been locked after 3 unsuccessful attempts. Please contact Helpdesk to gain access.'

export const NO_ASSIGNED_YARDS_ERROR =
  'You have no yards assigned. Please contact Helpdesk to provide access.'

export const NO_ASSIGNED_ROLE_ERROR =
  'You have no roles assigned. Please contact Helpdesk to provide access.'
