import { fetcher, storage } from '@copart/ops-tool-kit'
import { string } from '@copart/front-end-utils'
import { map } from 'ramda'

export const getCobaltUserFacilities = async (country, role) => {
  try {
    const url = string.substitute(storage.getItem('config').endpoints.allFacilities, {
      country: country.toLowerCase(),
    })
    const {
      data: { data: response },
    } = await fetcher.get(url, {
      headers: {
        country,
        AUTHORIZATIONROLE: role,
        ...(storage.activeLanguage === 'fi'
          ? { language: 'en', LANGUAGECODE: 'en' }
          : {}),
      },
    })
    const yardObject = map(
      (val) => ({
        yard_number: val.facility_id,
        yard_name: val.facility_nm,
      }),
      response,
    )
    return yardObject
  } catch (error) {
    return Promise.reject('Unable to fetch Facilities')
  }
}
